.button {
  margin-top: 32px;
}

.is-android {
  margin-top: 48px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}

.device {
  width: 100%;
  margin-top: 20px;
  padding: 16px 20px;
  background: #ffffff;
  border-radius: 20px;
  color: #333333;
}
.device__head {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  line-height: 20px;
}
.device__name {
  flex-shrink: 0;
  margin-right: auto;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}
.device__icon {
  flex-shrink: 0;
  width: auto !important;
  height: 12px;
  object-fit: contain;
  pointer-events: none;
}
.device__desc {
  font-size: 12px;
  line-height: 16px;
  white-space: pre-line;
}

/* 设置不同icon大小 */
.device__icon[alt='华为'] {
  height: 16px;
}
.device__icon[alt='荣耀'] {
  height: 8px;
  margin-left: 12px;
}
.device__icon[alt='小米'] {
  height: 16px;
}
.device__icon[alt='REDMI'] {
  height: 8px;
  margin-left: 12px;
}
.device__icon[alt='OPPO'] {
  height: 8px;
}
.device__icon[alt='vivo'] {
  height: 10px;
}
.device__icon[alt='iQOO'] {
  height: 10px;
  margin-left: 16px;
}
.device__icon[alt='三星'] {
  height: 8px;
}
